@font-face {
  font-family: "mwffHeadFont";
  src: local("mwffHeadFont"), url(./assets/fonts/shlop\ rg.otf) format("woff");
}
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

$mwffsubfont: "Cinzel";
$mwffHeadFont: "mwffHeadFont";
$mwffNavFont: "Abel";
$mwffTextFont: "Roboto";

$mwffblack: #19180a;
$mwffwhite: #f4f3ee;
$mwffred: #801b1b;
$mwffgreen: #5cb83b;

* {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  width: 100vw;
}
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: $mwffblack;
}
.navbarcontainer {
  z-index: 500;
  position: fixed;
  top: 0px;
  height: 90px;
  width: 100vw;
  display: flex;
  justify-content: right;
  padding-right: 7vw;
  transition: all ease 0.2s;
  background-color: $mwffred;
  // box-shadow: 2px 5px 5px rgba(10, 10, 10, 0.4);
  .mobilemenubtn {
    display: none;
  }
  .slogan {
    position: absolute;
    left: 15vw;
    height: 150%;
    top: 15%;
  }
  ul {
    padding-left: 0;
    list-style: none;
    margin-top: 35px;
    transition: all ease 0.2s;
    a {
      margin-right: 20px;
      margin-left: 20px;
      font-size: 15pt;
      text-decoration: none;
      color: $mwffwhite;
      opacity: 0.9;
      transition: all ease 0.2s;
      font-family: $mwffNavFont;
      font-weight: 300;
      // text-shadow: 0px 0px 0px red;
      display: inline-block;
      &:hover {
        opacity: 1;
        // text-shadow: 0px 3px 0px red;
        transform: scale(1.07);
      }
    }
    .activenav {
      border-bottom: 1px solid white;
    }
  }
}
.navscrolled {
  transition: all ease 0.2s;
  height: 65px;
  .slogan {
    position: absolute;
    height: 125%;
    top: 5%;
  }
  ul {
    margin-top: 20px;
    transition: all ease 0.2s;
    a {
      margin-right: 19px;
      margin-left: 19px;
    }
  }
}
.homecontainer {
  width: 100vw;
  height: 84vh;
  min-height: 400px;
  position: relative;
  .slogancontainer {
    width: 100vw;
    position: absolute;
    top: calc(28vh - 30px);
    left: 10px;
    z-index: 100;
    color: $mwffwhite;
    text-align: center;
    .slogan {
      width: calc(25vw + 80px);
      transform: rotate(0deg);
      // filter: saturate(0);
    }
  }
  .laxcontainer {
    /* The image used */
    background-image: url("./assets/images/mwffBannerPic.png");

    /* Full height */
    height: 100vh;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .imgfilter {
    position: absolute;
    top: 0;
    left: 0;
    height: 84vh;
    width: 100vw;
    background-color: #460b0b;
    opacity: 0.1;
    z-index: 100;
  }
  .bottomgradient {
    background: linear-gradient(rgba(0, 0, 0, 0), $mwffblack);
    height: 15vh;
    width: 100vw;
    position: absolute;
    bottom: 0;
    z-index: 100;
  }
  .topbtn {
    position: fixed;
    height: 60px;
    width: 60px;
    bottom: 50px;
    right: -90px;
    transition: ease all 0.2s;
    z-index: 999;
    background-color: $mwffred;
    font-size: 13pt;
    font-family: $mwffNavFont;
    line-height: 60px;
    opacity: 0.9;
    border: solid 1px $mwffblack;
    border-radius: 3px;
    text-align: center;
    color: $mwffwhite;
    text-decoration: none;
    &:hover {
      cursor: pointer;
      opacity: 1;
      transform: translateY(-3px);
      box-shadow: 0px 3px 0px $mwffblack;
    }
  }
  .activetopbtn {
    transition: ease all 0.2s;
    right: 30px;
  }
}
.aboutcontainer {
  width: 100vw;
  height: 35vw;
  min-height: 400px;
  background-color: $mwffblack;
  z-index: 10;
  position: relative;
  .aboutref {
    position: absolute;
    top: 0px;
    margin-top: -90px;
    left: 0;
    width: 100vw;
    height: 10px;
    opacity: 0;
  }
  h1 {
    text-align: center;
    font-family: $mwffHeadFont;
    position: absolute;
    right: 0;
    width: 85vw;
    color: $mwffwhite;
    font-size: 40pt;
    margin: 0;
  }
  .leftpic {
    width: 50vw;
    height: 100%;
    position: absolute;
    left: 10vw;
    top: 5vh;
    .leftimg {
      background: linear-gradient(
        135deg,
        #333 0%,
        #333 90%,
        transparent 90%,
        transparent 100%
      );
      width: 75%;
      box-shadow: 15px 15px 0px $mwffred;
    }
  }
  .rightdetails {
    top: 3vh;
    width: 50vw;
    height: 100%;
    padding: 50px;
    padding-right: 150px;
    position: absolute;
    right: 0;
    color: $mwffwhite;
    opacity: 0.9;
    font-size: 1.2vw;
    font-family: $mwffTextFont;
    line-height: 2vw;
  }
  .hr {
    width: 40vw;
    position: absolute;
    bottom: 60px;
    opacity: 0.5;
    left: 30vw;
    height: 1px;
    background-color: $mwffwhite;
  }
}
.howcontainer {
  width: 100vw;
  display: flex;
  height: 35vh;
  min-height: 400px;
  gap: 20px;
  padding: 0px 150px 10px 150px;
  background-color: $mwffblack;
  position: relative;
  .howref {
    position: absolute;
    top: 0px;
    margin-top: -90px;
    left: 0;
    width: 100vw;
    height: 10px;
    opacity: 0;
  }
  .how {
    display: flex;
    flex: 1;
    background-color: $mwffwhite;
    padding: 30px;
    position: relative;
    // border: solid 1px rgb(199, 149, 149);
    border-radius: 3px;
    box-shadow: -6px 6px 0px #5cb83b;
    z-index: 100;
    .submitbtn {
      text-decoration: none;
      text-align: center;
      position: absolute;
      width: 7vw;
      left: 50%;
      margin-left: -3.5vw;
      bottom: calc(5vh - 20px);
      background-color: $mwffred;
      color: $mwffwhite;
      padding: 10px;
      font-family: $mwffNavFont;
      font-size: 13pt;
      font-weight: 600;
      border: none;
      border-radius: 4px;
      opacity: 0.9;
      transition: ease all 0.2s;
      &:hover {
        cursor: pointer;
        opacity: 1;
        background-color: rgb(168, 39, 39);
        box-shadow: 0px 3px 3px $mwffblack;
        transform: translateY(-3px);
      }
    }
    .ticketbtn {
      margin-left: initial;
      left: 2vw;
    }
    .directions {
      margin-left: initial;
      left: initial;
      right: 2vw;
    }
    svg {
      font-size: 45pt;
      position: absolute;
      width: 60px;
      left: 50%;
      top: calc(12vh - 40px);
      margin-left: -30px;
      color: $mwffred;
      filter: drop-shadow(3px -3px 0px $mwffgreen)
        drop-shadow(-4px 3px 0px $mwffblack);
    }
    h1 {
      font-size: 1.2vw;
      font-family: $mwffsubfont;
      font-weight: 800;
      color: $mwffblack;
      text-align: center;
      width: 100%;
      margin-top: calc(18vh - 30px);
      text-transform: uppercase !important;
    }
    p {
      position: absolute;
      width: 80%;
      left: 11%;
      top: calc(25vh - 30px);
      font-family: $mwffTextFont;
      line-height: 22px;
      font-size: 11pt;
    }
  }
  .hr {
    width: 40vw;
    position: absolute;
    bottom: -55px;
    opacity: 0.5;
    left: 30vw;
    height: 1px;
    background-color: $mwffwhite;
    z-index: 100;
  }
}
.schedcontainer {
  padding-top: 50px;
  height: 75vh;
  min-height: 530px;
  width: 100vw;
  background-color: $mwffblack;
  position: relative;
  svg {
    position: absolute;
    top: 70px;
    right: -15px;
    font-size: 50pt;
    color: $mwffwhite;
  }
  .schedref {
    position: absolute;
    top: 0px;
    margin-top: -90px;
    left: 0;
    width: 100vw;
    height: 10px;
    opacity: 0;
  }
  h2 {
    font-size: 12pt;
    font-family: $mwffNavFont;
    width: 100vw;
    position: absolute;
    text-align: center;
    left: 0;
    color: $mwffwhite;
  }
  .datescontainer {
    width: 80%;
    position: absolute;
    left: 10%;
    height: 100%;
    display: flex;
    .date {
      display: flex;
      flex: 1;
      position: relative;
      .circle {
        width: 12vw;
        height: 12vw;
        background-color: $mwffred;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        margin-left: -6vw;
        box-shadow: 2px 1px 5px black;
        top: 70px;
        text-align: center;
        overflow: hidden;
        opacity: 0.9;
        img {
          width: 150%;
          height: 150%;
          opacity: 0;
        }
        h1 {
          position: absolute;
          top: 2vh;
          width: 100%;
          height: 50px;
          line-height: 50px;
          color: $mwffwhite;
          font-family: $mwffHeadFont;
          font-size: 2.2vw;
          text-shadow: -3px 4px 0px $mwffblack;
          span {
            font-family: $mwffNavFont;
            font-size: 1.2vw;
            text-shadow: none;
            line-height: 1.6vw;
            width: 80%;
            height: 80%;
            align-items: center;
            margin-left: 10%;
            margin-top: calc(3vw - 37px);
            display: block;
          }
          .smaller {
            font-size: 1vw;
          }
        }
      }
    }
    .date2 {
      .circle {
        top: initial;
        bottom: 15vh;
      }
    }
  }
  .hr {
    width: 40vw;
    position: absolute;
    bottom: -10px;
    opacity: 0.5;
    left: 30vw;
    height: 1px;
    background-color: $mwffwhite;
    z-index: 100;
  }
}
.categoriescontainer {
  height: 38vw;
  min-height: 420px;
  width: 100vw;
  background-color: $mwffblack;
  position: relative;
  .categoriesref {
    position: absolute;
    top: 0px;
    margin-top: -90px;
    left: 0;
    width: 100vw;
    height: 10px;
    opacity: 0;
  }
  .cattitle {
    position: absolute;
    width: 100vw;
    text-align: center;
    color: $mwffwhite;
    top: 0;
    left: 0;
    font-family: $mwffsubfont;
    display: block;
    font-size: 44pt;
  }
  .categorycontainer {
    display: flex;
    width: 100vw;
    position: relative;
    height: 95%;
    gap: 10px;
    padding: 140px 150px 90px 150px;
    .category {
      height: 100%;
      flex: 1;
      display: flex;
      background-color: $mwffwhite;
      border-radius: 15px;
      position: relative;
      .imgcontainer {
        width: 100%;
        height: 50%;
        overflow: hidden;
        object-fit: cover;
        img {
          min-width: 100%;
          max-width: 200px;
          min-height: 100%;
          max-height: 200px;
        }
      }
      .catdets {
        width: 100%;
        position: absolute;
        top: 50%;
        h1 {
          width: 100%;
          margin-left: 20px;
          margin-bottom: 0px !important;
          font-family: $mwffNavFont;
          svg {
            margin-bottom: -6px;
            margin-right: 15px;
          }
        }
        p {
          margin-left: 1.5vw;
          margin-top: 2vw;
          font-family: $mwffTextFont;
          width: 90%;
          span {
            border-radius: 4px;
            display: inline-block;
            padding: 5px 1vw;
            border: 1.5px solid black;
            box-shadow: 1px 2px 0px rgba(0, 0, 0, 0.2);
            margin: 0px 4px 10px 0px;
            text-transform: uppercase;
            font-size: 0.7vw;
          }
        }
      }
    }
  }
  .hr {
    width: 40vw;
    position: absolute;
    bottom: 30px;
    opacity: 0.5;
    left: 30vw;
    height: 1px;
    background-color: $mwffwhite;
    z-index: 100;
  }
}
.selectionscontainer {
  height: 45vw;
  width: 100vw;
  background-color: $mwffblack;
  position: relative;
  .selectionsref {
    position: absolute;
    top: 0px;
    margin-top: -90px;
    left: 0;
    width: 100vw;
    height: 10px;
    opacity: 0;
  }
  .selectionstitle {
    position: absolute;
    width: 100vw;
    text-align: center;
    color: $mwffwhite;
    top: 0;
    left: 0;
    font-family: $mwffsubfont;
    display: block;
    font-size: 44pt;
  }
  .selectioncontainer {
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer and Edge */
    width: 100vw;
    height: 100%;
    gap: 10px;
    padding: 140px 150px 90px 150px;
    position: relative;
    display: flex;
    .selection {
      // height: 100%;
      width: 22vw;
      background-color: $mwffwhite;
      border-radius: 5px;
      display: block;
      position: relative;
      .imgcontainer {
        width: 22vw;
        height: 100%;
        object-fit: cover;
        img {
          height: 100%;
          width: 22vw;
        }
      }
      .selectiondets {
        width: 100%;
        position: absolute;
        top: 83%;
        h1 {
          width: 100%;
          margin-left: 20px;
          margin-bottom: 0px !important;
          font-family: $mwffNavFont;
          svg {
            margin-bottom: -6px;
            margin-right: 15px;
          }
        }
        p {
          margin-left: 1.5vw;
          margin-top: 2vw;
          font-family: $mwffTextFont;
          width: 90%;
          span {
            border-radius: 4px;
            display: inline-block;
            padding: 5px 1vw;
            border: 1.5px solid black;
            box-shadow: 1px 2px 0px rgba(0, 0, 0, 0.2);
            margin: 0px 4px 10px 0px;
            text-transform: uppercase;
            font-size: 0.7vw;
          }
        }
      }
    }
  }
  .hr {
    width: 40vw;
    position: absolute;
    bottom: 30px;
    opacity: 0.5;
    left: 30vw;
    height: 1px;
    background-color: $mwffwhite;
    z-index: 100;
  }
}
.awardscontainer {
  padding-bottom: 30px;
  position: relative;
  .awardsref {
    position: absolute;
    top: 0px;
    margin-top: -90px;
    left: 0;
    width: 100vw;
    height: 10px;
    opacity: 0;
  }
  h1 {
    color: $mwffwhite;
    font-size: 44pt;
    font-family: $mwffsubfont;
    margin-top: 10px;
  }
  ul {
    width: 90vw;
    margin-left: 5vw;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    list-style: none;
    li {
      color: white;
      width: calc(100% / 5);
      height: 15vw;
      position: relative;
      svg {
        font-size: 45pt;
        margin-top: 18%;
      }
      h2 {
        text-align: center;
        position: absolute;
        width: 80%;
        top: 50%;
        margin-left: 10%;
        font-family: $mwffNavFont;
      }
    }
    li:nth-child(odd) {
      background-color: rgba(200, 200, 200, 0.2);
    }
  }
}
.partnerscontainer {
  width: 100vw;
  height: 35vh;
  min-height: 400px;
  padding: 0px 150px 10px 150px;
  background-color: $mwffblack;
  position: relative;
  .partnersref {
    position: absolute;
    top: 0px;
    margin-top: -90px;
    left: 0;
    width: 100vw;
    height: 10px;
    opacity: 0;
  }
  .subcontainer {
    position: relative;
    height: 100%;
    padding-top: 80px;
    display: flex;
    flex: 1;
    gap: 4vw;
    flex-wrap: wrap;
    align-items: center;
    .partner {
      display: flex;
      flex: 1;
      img {
        width: 6vw;

        z-index: 100;
      }
    }
  }
}
.contactcontainer {
  height: 36vw;
  min-height: 400px;
  width: 100vw;
  background-color: $mwffblack;
  position: relative;
}
.footercontainer {
  background-color: rgb(158, 51, 51);
  margin-top: 20px;
  display: flex;
  width: 100vw;
  position: relative;
  height: 22vh;
  min-height: 220px;
  gap: 50px;
  padding: 30px 100px;
  .disclaimercontainer {
    flex: 1;
    color: $mwffwhite;
    font-family: $mwffTextFont;
    padding-left: 5vw;
    p {
      margin-top: 0;
      a {
        text-decoration: none;
        color: $mwffblack;
      }
    }
    input {
      height: 40px;
      width: 200px;
      position: relative;
      padding-left: 10px;
      font-family: $mwffTextFont;
      font-size: 11pt;
      outline: none;
      margin-left: 1px;
    }
  }
  .logocontainer {
    // height: 100%;
    display: flex;
    justify-content: flex-start;
    flex: 1;
    width: calc(5vw + 70px);
    height: calc(5vw + 70px);
    margin-right: 3vw;
  }
  .socialscontainer {
    // height: 100%;
    // flex: 1;
    float: right;
    margin-right: 12vw;
    .donatebtn {
      width: 150px;
      height: 40px;
      display: block;
      background-color: $mwffblack;
      color: $mwffwhite;
      text-decoration: none;
      font-size: 16pt;
      font-family: $mwffNavFont;
      text-align: center;
      line-height: 40px;
      border-radius: 5px;
      transition: ease all 0.2;
      opacity: 0.8;
      &:hover {
        cursor: pointer;
        opacity: 1;
        transform: translateY(-2px);
      }
    }
    svg {
      margin-right: 20px;
      font-size: 30pt;
      color: $mwffwhite;
      transition: ease all 0.2s;
      opacity: 0.8;
      &:hover {
        color: white;
        cursor: pointer;
        transform: translateY(-2px);
        opacity: 1;
      }
    }
  }
  .cityimg {
    width: 100vw;
    position: absolute;
    bottom: 0;
    left: 0;
    filter: brightness(0.8);
  }
}

@media (max-width: 1020px) {
  .navbarcontainer {
    height: 50px;
    padding-right: 0;
    .mobilemenubtn {
      display: block;
      position: absolute;
      top: 19px;
      right: 20px;
      width: 30px;
      height: 15px;
      .btntop {
        width: 100%;
        height: 2px;
        background-color: white;
        position: absolute;
        top: 0;
        transition: ease all 0.2s;
      }
      .btnbot {
        width: 100%;
        height: 2px;
        background-color: white;
        position: absolute;
        bottom: 0;
        transition: ease all 0.2s;
      }
    }
    .openmenubtn {
      .btntop {
        transform: rotate(45deg);
        top: 50%;
      }
      .btnbot {
        transform: rotate(-45deg);
        bottom: 39%;
      }
    }
    .slogan {
      position: absolute;
      left: 50%;
      margin-left: -8.5%;
      transition: ease all 0.2s;
    }
    ul {
      margin-top: 55px;
      margin-right: -55%;
      a {
        margin-right: 0px;
        background-color: $mwffblack;
        margin-top: 5px;
        display: block;
        padding: 10px 15px;
        opacity: 0.95;
      }
    }
    .activenavmenu {
      margin-right: 5px !important;
    }
  }
  .homecontainer {
    height: 60vh;
    .slogancontainer {
      top: calc(18vh - 30px);
      .slogan {
        width: 80vw;
        transform: rotate(0deg);
      }
    }
    .laxcontainer {
      /* The image used */
      background-image: url("./assets/images/mwffBannerPic.png");

      background-position: top;
      background-size: 1100px 580px;
    }
    .imgfilter {
      height: 60vh;
    }
    .bottomgradient {
      bottom: -5px;
    }
  }
  .aboutcontainer {
    height: 80vh;
    min-height: 600px;
    .leftpic {
      width: 88vw;
      height: 50%;
      position: absolute;
      left: 5vw;
      top: 5vh;
      .leftimg {
        width: 100%;
        box-shadow: 15px 15px 0px $mwffred;
      }
    }
    .rightdetails {
      top: calc(25vh + 70px);
      width: 100vw;
      padding: 30px;
      padding-right: 30px;
      padding-left: 40px;
      font-size: 3.5vw;
      line-height: 5vw;
      height: 50%;
    }
  }
  .howcontainer {
    display: inline-block;
    height: initial;
    gap: 10px;
    padding: 10px;
    align-content: flex-start;
    .how {
      height: 150px;
      display: inline-block;
      width: 90%;
      margin-left: 5%;
      margin-bottom: 5%;
      min-height: 290px;
      background-color: $mwffwhite;
      padding: 30px;
      position: relative;
      border-radius: 3px;
      box-shadow: -6px 6px 0px #5cb83b;
      h1 {
        font-size: 17pt;
        font-family: $mwffsubfont;
        font-weight: 800;
        color: $mwffblack;
        text-align: center;
        width: 100%;
        margin-top: calc(12vh - 30px);
        text-transform: uppercase !important;
      }
      p {
        position: absolute;
        width: 80%;
        left: 11%;
        top: calc(22vh - 30px);
        font-family: $mwffTextFont;
        line-height: 22px;
        font-size: 12pt;
      }
      .submitbtn {
        width: 100px;
        right: 10px;
        left: initial;
        margin-left: 0px;
        bottom: 10px;
        background-color: $mwffred;
        color: $mwffwhite;
        padding: 8px 10px;
        font-family: $mwffNavFont;
        font-size: 13pt;
        font-weight: 400;
      }
      .ticketbtn {
        bottom: 15px;
      }
      .directions {
        bottom: 15px;
        left: 5vw;
      }
      svg {
        font-size: 40pt;
        position: absolute;
        width: 60px;
        left: 50%;
        top: calc(9vh - 40px);
        margin-left: -30px;
      }
    }
  }
  .schedcontainer {
    padding-top: 50px;
    padding-bottom: 20px;
    height: 79vh;
    min-height: 630px;
    width: 100vw;
    background-color: $mwffblack;
    position: relative;
    svg {
      position: absolute;
      top: 70px;
      right: -15px;
      font-size: 50pt;
      color: $mwffwhite;
    }
    .schedref {
      margin-top: -90px;
    }
    h2 {
      font-size: 15pt;
      font-family: $mwffNavFont;
      width: 100vw;
      position: absolute;
      text-align: center;
      left: 0;
      color: $mwffwhite;
    }
    .datescontainer {
      width: 100vw;
      position: absolute;
      left: 1vw;
      display: block;
      flex-wrap: wrap;
      height: 100vh;
      .date {
        width: 45vw;
        height: 30vw;
        position: relative;
        display: inline-block;
        .circle {
          width: 100%;
          height: 100%;
          background-color: $mwffred;
          border-radius: 5px;
          position: absolute;
          left: 0.5vw;
          margin-left: 2.5vw;
          box-shadow: 2px 1px 5px black;
          top: 60px;
          text-align: center;
          overflow: hidden;
          h1 {
            top: 0;
            width: 100%;
            height: 50px;
            line-height: 30px;
            color: $mwffwhite;
            font-family: $mwffHeadFont;
            font-size: 21pt;
            text-shadow: -3px 4px 0px $mwffblack;
            span {
              font-family: $mwffNavFont;
              font-size: 12pt;
              text-shadow: none;
              margin-top: 10px;
              line-height: 20px;
              width: 80%;
              margin-left: 10%;
              display: block;
            }
            .smaller {
              font-size: 10pt;
            }
          }
        }
      }
      .date2 {
        .circle {
          top: 60px;
          bottom: initial;
          margin-left: 5vw;
        }
      }
    }
  }
  .categoriescontainer {
    height: 45vh;
    min-height: 420px;
    width: 100vw;
    padding-bottom: 20px;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer and Edge */
    .categoriesref {
      margin-top: -90px;
    }
    .cattitle {
      position: absolute;
      width: 100vw;
      text-align: center;
      color: $mwffwhite;
      top: 0;
      left: 0;
      font-family: $mwffsubfont;
      display: block;
      font-size: 24pt;
    }
    .categorycontainer {
      position: relative;
      height: 100%;
      gap: 10px;
      padding: 40px 20px 85px 40px;
      overflow-x: scroll;
      overflow-y: hidden;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* Internet Explorer and Edge */
      .category {
        width: 50vw;
        display: block;
        flex: initial;
        margin-top: 50px;
        .imgcontainer {
          width: 100%;
          height: 50%;
        }
        .catdets {
          width: 100%;
          position: absolute;
          top: 50%;
          h1 {
            width: 100%;
            margin-left: 10px;
            font-family: $mwffNavFont;
            font-size: 17pt;
            svg {
              margin-bottom: -4px;
              margin-right: 10px;
            }
          }
          p {
            margin-left: 20px;
            font-family: $mwffTextFont;
            width: 90%;
            span {
              border-radius: 4px;
              display: inline-block;
              padding: 3px 7px;
              border: 1px solid black;
              box-shadow: 1px 2px 0px rgba(0, 0, 0, 0.2);
              margin: 0px 4px 10px 0px;
              text-transform: uppercase;
              font-size: 2.5vw;
            }
          }
        }
      }
    }
    .hr {
      bottom: 0px;
    }
  }
  .selectionscontainer {
    height: 73vh;
    width: 100vw;
    background-color: $mwffblack;
    position: relative;
    .selectionsref {
      position: absolute;
      top: 0px;
      margin-top: -90px;
      left: 0;
      width: 100vw;
      height: 10px;
      opacity: 0;
    }
    .selectionstitle {
      position: absolute;
      width: 100vw;
      text-align: center;
      color: $mwffwhite;
      top: 0;
      left: 0;
      font-family: $mwffsubfont;
      display: block;
      font-size: 24pt;
    }
    .selectioncontainer {
      overflow-x: scroll;
      overflow-y: hidden;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* Internet Explorer and Edge */
      width: 100vw;
      height: 100%;
      gap: 10px;
      padding: 130px 30px 60px 20px;
      position: relative;
      display: flex;
      .selection {
        // height: 100%;
        width: 70vw;
        background-color: $mwffwhite;
        border-radius: 5px;
        display: block;
        position: relative;
        .selectioncount {
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 50px;
          padding-left: 5px;
          background-color: white;
          line-height: 20px;
        }
        .imgcontainer {
          width: 70vw;
          height: 100%;
          object-fit: cover;
          img {
            height: 100%;
            width: 70vw;
          }
        }
      }
    }
  }
  .awardscontainer {
    padding-bottom: 30px;
    h1 {
      font-size: 24pt;
    }
    ul {
      width: 100vw;
      margin-left: 0vw;
      padding: 0;
      li {
        color: white;
        width: calc(100% / 3);
        height: 28vw;
        svg {
          font-size: 22pt;
          margin-top: 8%;
        }
        h2 {
          text-align: center;
          position: absolute;
          width: 80%;
          top: 30%;
          margin-left: 10%;
          font-family: $mwffNavFont;
          font-size: 11pt;
        }
      }
      li:nth-child(odd) {
        background-color: rgba(200, 200, 200, 0.2);
      }
    }
  }
  .footercontainer {
    padding: 10px 5px;
    height: 35vh;
    min-height: 280px;
    .disclaimercontainer {
      font-size: 3vw;
      .cccplug {
        font-family: $mwffNavFont;
        color: $mwffwhite;
        // font-size: 4vw;
        a {
          color: $mwffblack;
          text-decoration: none;
        }
      }
    }
    .logocontainer {
      position: absolute;
      bottom: 40px;
      left: 12%;
      height: 120px;
      width: 120px;
    }
    svg {
      opacity: 1;
    }
  }
}
