@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@font-face {
  font-family: "mwffHeadFont";
  src: local("mwffHeadFont"), url(./assets/fonts/shlop\ rg.otf) format("woff");
}
* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  width: 100vw;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #19180a;
}

.navbarcontainer {
  z-index: 500;
  position: fixed;
  top: 0px;
  height: 90px;
  width: 100vw;
  display: flex;
  justify-content: right;
  padding-right: 7vw;
  transition: all ease 0.2s;
  background-color: #801b1b;
}
.navbarcontainer .mobilemenubtn {
  display: none;
}
.navbarcontainer .slogan {
  position: absolute;
  left: 15vw;
  height: 150%;
  top: 15%;
}
.navbarcontainer ul {
  padding-left: 0;
  list-style: none;
  margin-top: 35px;
  transition: all ease 0.2s;
}
.navbarcontainer ul a {
  margin-right: 20px;
  margin-left: 20px;
  font-size: 15pt;
  text-decoration: none;
  color: #f4f3ee;
  opacity: 0.9;
  transition: all ease 0.2s;
  font-family: "Abel";
  font-weight: 300;
  display: inline-block;
}
.navbarcontainer ul a:hover {
  opacity: 1;
  transform: scale(1.07);
}
.navbarcontainer ul .activenav {
  border-bottom: 1px solid white;
}

.navscrolled {
  transition: all ease 0.2s;
  height: 65px;
}
.navscrolled .slogan {
  position: absolute;
  height: 125%;
  top: 5%;
}
.navscrolled ul {
  margin-top: 20px;
  transition: all ease 0.2s;
}
.navscrolled ul a {
  margin-right: 19px;
  margin-left: 19px;
}

.homecontainer {
  width: 100vw;
  height: 84vh;
  min-height: 400px;
  position: relative;
}
.homecontainer .slogancontainer {
  width: 100vw;
  position: absolute;
  top: calc(28vh - 30px);
  left: 10px;
  z-index: 100;
  color: #f4f3ee;
  text-align: center;
}
.homecontainer .slogancontainer .slogan {
  width: calc(25vw + 80px);
  transform: rotate(0deg);
}
.homecontainer .laxcontainer {
  /* The image used */
  background-image: url("./assets/images/mwffBannerPic.png");
  /* Full height */
  height: 100vh;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.homecontainer .imgfilter {
  position: absolute;
  top: 0;
  left: 0;
  height: 84vh;
  width: 100vw;
  background-color: #460b0b;
  opacity: 0.1;
  z-index: 100;
}
.homecontainer .bottomgradient {
  background: linear-gradient(rgba(0, 0, 0, 0), #19180a);
  height: 15vh;
  width: 100vw;
  position: absolute;
  bottom: 0;
  z-index: 100;
}
.homecontainer .topbtn {
  position: fixed;
  height: 60px;
  width: 60px;
  bottom: 50px;
  right: -90px;
  transition: ease all 0.2s;
  z-index: 999;
  background-color: #801b1b;
  font-size: 13pt;
  font-family: "Abel";
  line-height: 60px;
  opacity: 0.9;
  border: solid 1px #19180a;
  border-radius: 3px;
  text-align: center;
  color: #f4f3ee;
  text-decoration: none;
}
.homecontainer .topbtn:hover {
  cursor: pointer;
  opacity: 1;
  transform: translateY(-3px);
  box-shadow: 0px 3px 0px #19180a;
}
.homecontainer .activetopbtn {
  transition: ease all 0.2s;
  right: 30px;
}

.aboutcontainer {
  width: 100vw;
  height: 35vw;
  min-height: 400px;
  background-color: #19180a;
  z-index: 10;
  position: relative;
}
.aboutcontainer .aboutref {
  position: absolute;
  top: 0px;
  margin-top: -90px;
  left: 0;
  width: 100vw;
  height: 10px;
  opacity: 0;
}
.aboutcontainer h1 {
  text-align: center;
  font-family: "mwffHeadFont";
  position: absolute;
  right: 0;
  width: 85vw;
  color: #f4f3ee;
  font-size: 40pt;
  margin: 0;
}
.aboutcontainer .leftpic {
  width: 50vw;
  height: 100%;
  position: absolute;
  left: 10vw;
  top: 5vh;
}
.aboutcontainer .leftpic .leftimg {
  background: linear-gradient(135deg, #333 0%, #333 90%, transparent 90%, transparent 100%);
  width: 75%;
  box-shadow: 15px 15px 0px #801b1b;
}
.aboutcontainer .rightdetails {
  top: 3vh;
  width: 50vw;
  height: 100%;
  padding: 50px;
  padding-right: 150px;
  position: absolute;
  right: 0;
  color: #f4f3ee;
  opacity: 0.9;
  font-size: 1.2vw;
  font-family: "Roboto";
  line-height: 2vw;
}
.aboutcontainer .hr {
  width: 40vw;
  position: absolute;
  bottom: 60px;
  opacity: 0.5;
  left: 30vw;
  height: 1px;
  background-color: #f4f3ee;
}

.howcontainer {
  width: 100vw;
  display: flex;
  height: 35vh;
  min-height: 400px;
  gap: 20px;
  padding: 0px 150px 10px 150px;
  background-color: #19180a;
  position: relative;
}
.howcontainer .howref {
  position: absolute;
  top: 0px;
  margin-top: -90px;
  left: 0;
  width: 100vw;
  height: 10px;
  opacity: 0;
}
.howcontainer .how {
  display: flex;
  flex: 1;
  background-color: #f4f3ee;
  padding: 30px;
  position: relative;
  border-radius: 3px;
  box-shadow: -6px 6px 0px #5cb83b;
  z-index: 100;
}
.howcontainer .how .submitbtn {
  text-decoration: none;
  text-align: center;
  position: absolute;
  width: 7vw;
  left: 50%;
  margin-left: -3.5vw;
  bottom: calc(5vh - 20px);
  background-color: #801b1b;
  color: #f4f3ee;
  padding: 10px;
  font-family: "Abel";
  font-size: 13pt;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  opacity: 0.9;
  transition: ease all 0.2s;
}
.howcontainer .how .submitbtn:hover {
  cursor: pointer;
  opacity: 1;
  background-color: rgb(168, 39, 39);
  box-shadow: 0px 3px 3px #19180a;
  transform: translateY(-3px);
}
.howcontainer .how .ticketbtn {
  margin-left: initial;
  left: 2vw;
}
.howcontainer .how .directions {
  margin-left: initial;
  left: initial;
  right: 2vw;
}
.howcontainer .how svg {
  font-size: 45pt;
  position: absolute;
  width: 60px;
  left: 50%;
  top: calc(12vh - 40px);
  margin-left: -30px;
  color: #801b1b;
  filter: drop-shadow(3px -3px 0px #5cb83b) drop-shadow(-4px 3px 0px #19180a);
}
.howcontainer .how h1 {
  font-size: 1.2vw;
  font-family: "Cinzel";
  font-weight: 800;
  color: #19180a;
  text-align: center;
  width: 100%;
  margin-top: calc(18vh - 30px);
  text-transform: uppercase !important;
}
.howcontainer .how p {
  position: absolute;
  width: 80%;
  left: 11%;
  top: calc(25vh - 30px);
  font-family: "Roboto";
  line-height: 22px;
  font-size: 11pt;
}
.howcontainer .hr {
  width: 40vw;
  position: absolute;
  bottom: -55px;
  opacity: 0.5;
  left: 30vw;
  height: 1px;
  background-color: #f4f3ee;
  z-index: 100;
}

.schedcontainer {
  padding-top: 50px;
  height: 75vh;
  min-height: 530px;
  width: 100vw;
  background-color: #19180a;
  position: relative;
}
.schedcontainer svg {
  position: absolute;
  top: 70px;
  right: -15px;
  font-size: 50pt;
  color: #f4f3ee;
}
.schedcontainer .schedref {
  position: absolute;
  top: 0px;
  margin-top: -90px;
  left: 0;
  width: 100vw;
  height: 10px;
  opacity: 0;
}
.schedcontainer h2 {
  font-size: 12pt;
  font-family: "Abel";
  width: 100vw;
  position: absolute;
  text-align: center;
  left: 0;
  color: #f4f3ee;
}
.schedcontainer .datescontainer {
  width: 80%;
  position: absolute;
  left: 10%;
  height: 100%;
  display: flex;
}
.schedcontainer .datescontainer .date {
  display: flex;
  flex: 1;
  position: relative;
}
.schedcontainer .datescontainer .date .circle {
  width: 12vw;
  height: 12vw;
  background-color: #801b1b;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  margin-left: -6vw;
  box-shadow: 2px 1px 5px black;
  top: 70px;
  text-align: center;
  overflow: hidden;
  opacity: 0.9;
}
.schedcontainer .datescontainer .date .circle img {
  width: 150%;
  height: 150%;
  opacity: 0;
}
.schedcontainer .datescontainer .date .circle h1 {
  position: absolute;
  top: 2vh;
  width: 100%;
  height: 50px;
  line-height: 50px;
  color: #f4f3ee;
  font-family: "mwffHeadFont";
  font-size: 2.2vw;
  text-shadow: -3px 4px 0px #19180a;
}
.schedcontainer .datescontainer .date .circle h1 span {
  font-family: "Abel";
  font-size: 1.2vw;
  text-shadow: none;
  line-height: 1.6vw;
  width: 80%;
  height: 80%;
  align-items: center;
  margin-left: 10%;
  margin-top: calc(3vw - 37px);
  display: block;
}
.schedcontainer .datescontainer .date .circle h1 .smaller {
  font-size: 1vw;
}
.schedcontainer .datescontainer .date2 .circle {
  top: initial;
  bottom: 15vh;
}
.schedcontainer .hr {
  width: 40vw;
  position: absolute;
  bottom: -10px;
  opacity: 0.5;
  left: 30vw;
  height: 1px;
  background-color: #f4f3ee;
  z-index: 100;
}

.categoriescontainer {
  height: 38vw;
  min-height: 420px;
  width: 100vw;
  background-color: #19180a;
  position: relative;
}
.categoriescontainer .categoriesref {
  position: absolute;
  top: 0px;
  margin-top: -90px;
  left: 0;
  width: 100vw;
  height: 10px;
  opacity: 0;
}
.categoriescontainer .cattitle {
  position: absolute;
  width: 100vw;
  text-align: center;
  color: #f4f3ee;
  top: 0;
  left: 0;
  font-family: "Cinzel";
  display: block;
  font-size: 44pt;
}
.categoriescontainer .categorycontainer {
  display: flex;
  width: 100vw;
  position: relative;
  height: 95%;
  gap: 10px;
  padding: 140px 150px 90px 150px;
}
.categoriescontainer .categorycontainer .category {
  height: 100%;
  flex: 1;
  display: flex;
  background-color: #f4f3ee;
  border-radius: 15px;
  position: relative;
}
.categoriescontainer .categorycontainer .category .imgcontainer {
  width: 100%;
  height: 50%;
  overflow: hidden;
  -o-object-fit: cover;
     object-fit: cover;
}
.categoriescontainer .categorycontainer .category .imgcontainer img {
  min-width: 100%;
  max-width: 200px;
  min-height: 100%;
  max-height: 200px;
}
.categoriescontainer .categorycontainer .category .catdets {
  width: 100%;
  position: absolute;
  top: 50%;
}
.categoriescontainer .categorycontainer .category .catdets h1 {
  width: 100%;
  margin-left: 20px;
  margin-bottom: 0px !important;
  font-family: "Abel";
}
.categoriescontainer .categorycontainer .category .catdets h1 svg {
  margin-bottom: -6px;
  margin-right: 15px;
}
.categoriescontainer .categorycontainer .category .catdets p {
  margin-left: 1.5vw;
  margin-top: 2vw;
  font-family: "Roboto";
  width: 90%;
}
.categoriescontainer .categorycontainer .category .catdets p span {
  border-radius: 4px;
  display: inline-block;
  padding: 5px 1vw;
  border: 1.5px solid black;
  box-shadow: 1px 2px 0px rgba(0, 0, 0, 0.2);
  margin: 0px 4px 10px 0px;
  text-transform: uppercase;
  font-size: 0.7vw;
}
.categoriescontainer .hr {
  width: 40vw;
  position: absolute;
  bottom: 30px;
  opacity: 0.5;
  left: 30vw;
  height: 1px;
  background-color: #f4f3ee;
  z-index: 100;
}

.selectionscontainer {
  height: 45vw;
  width: 100vw;
  background-color: #19180a;
  position: relative;
}
.selectionscontainer .selectionsref {
  position: absolute;
  top: 0px;
  margin-top: -90px;
  left: 0;
  width: 100vw;
  height: 10px;
  opacity: 0;
}
.selectionscontainer .selectionstitle {
  position: absolute;
  width: 100vw;
  text-align: center;
  color: #f4f3ee;
  top: 0;
  left: 0;
  font-family: "Cinzel";
  display: block;
  font-size: 44pt;
}
.selectionscontainer .selectioncontainer {
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  width: 100vw;
  height: 100%;
  gap: 10px;
  padding: 140px 150px 90px 150px;
  position: relative;
  display: flex;
}
.selectionscontainer .selectioncontainer .selection {
  width: 22vw;
  background-color: #f4f3ee;
  border-radius: 5px;
  display: block;
  position: relative;
}
.selectionscontainer .selectioncontainer .selection .imgcontainer {
  width: 22vw;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.selectionscontainer .selectioncontainer .selection .imgcontainer img {
  height: 100%;
  width: 22vw;
}
.selectionscontainer .selectioncontainer .selection .selectiondets {
  width: 100%;
  position: absolute;
  top: 83%;
}
.selectionscontainer .selectioncontainer .selection .selectiondets h1 {
  width: 100%;
  margin-left: 20px;
  margin-bottom: 0px !important;
  font-family: "Abel";
}
.selectionscontainer .selectioncontainer .selection .selectiondets h1 svg {
  margin-bottom: -6px;
  margin-right: 15px;
}
.selectionscontainer .selectioncontainer .selection .selectiondets p {
  margin-left: 1.5vw;
  margin-top: 2vw;
  font-family: "Roboto";
  width: 90%;
}
.selectionscontainer .selectioncontainer .selection .selectiondets p span {
  border-radius: 4px;
  display: inline-block;
  padding: 5px 1vw;
  border: 1.5px solid black;
  box-shadow: 1px 2px 0px rgba(0, 0, 0, 0.2);
  margin: 0px 4px 10px 0px;
  text-transform: uppercase;
  font-size: 0.7vw;
}
.selectionscontainer .hr {
  width: 40vw;
  position: absolute;
  bottom: 30px;
  opacity: 0.5;
  left: 30vw;
  height: 1px;
  background-color: #f4f3ee;
  z-index: 100;
}

.awardscontainer {
  padding-bottom: 30px;
  position: relative;
}
.awardscontainer .awardsref {
  position: absolute;
  top: 0px;
  margin-top: -90px;
  left: 0;
  width: 100vw;
  height: 10px;
  opacity: 0;
}
.awardscontainer h1 {
  color: #f4f3ee;
  font-size: 44pt;
  font-family: "Cinzel";
  margin-top: 10px;
}
.awardscontainer ul {
  width: 90vw;
  margin-left: 5vw;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  list-style: none;
}
.awardscontainer ul li {
  color: white;
  width: 20%;
  height: 15vw;
  position: relative;
}
.awardscontainer ul li svg {
  font-size: 45pt;
  margin-top: 18%;
}
.awardscontainer ul li h2 {
  text-align: center;
  position: absolute;
  width: 80%;
  top: 50%;
  margin-left: 10%;
  font-family: "Abel";
}
.awardscontainer ul li:nth-child(odd) {
  background-color: rgba(200, 200, 200, 0.2);
}

.partnerscontainer {
  width: 100vw;
  height: 35vh;
  min-height: 400px;
  padding: 0px 150px 10px 150px;
  background-color: #19180a;
  position: relative;
}
.partnerscontainer .partnersref {
  position: absolute;
  top: 0px;
  margin-top: -90px;
  left: 0;
  width: 100vw;
  height: 10px;
  opacity: 0;
}
.partnerscontainer .subcontainer {
  position: relative;
  height: 100%;
  padding-top: 80px;
  display: flex;
  flex: 1;
  gap: 4vw;
  flex-wrap: wrap;
  align-items: center;
}
.partnerscontainer .subcontainer .partner {
  display: flex;
  flex: 1;
}
.partnerscontainer .subcontainer .partner img {
  width: 6vw;
  z-index: 100;
}

.contactcontainer {
  height: 36vw;
  min-height: 400px;
  width: 100vw;
  background-color: #19180a;
  position: relative;
}

.footercontainer {
  background-color: rgb(158, 51, 51);
  margin-top: 20px;
  display: flex;
  width: 100vw;
  position: relative;
  height: 22vh;
  min-height: 220px;
  gap: 50px;
  padding: 30px 100px;
}
.footercontainer .disclaimercontainer {
  flex: 1;
  color: #f4f3ee;
  font-family: "Roboto";
  padding-left: 5vw;
}
.footercontainer .disclaimercontainer p {
  margin-top: 0;
}
.footercontainer .disclaimercontainer p a {
  text-decoration: none;
  color: #19180a;
}
.footercontainer .disclaimercontainer input {
  height: 40px;
  width: 200px;
  position: relative;
  padding-left: 10px;
  font-family: "Roboto";
  font-size: 11pt;
  outline: none;
  margin-left: 1px;
}
.footercontainer .logocontainer {
  display: flex;
  justify-content: flex-start;
  flex: 1;
  width: calc(5vw + 70px);
  height: calc(5vw + 70px);
  margin-right: 3vw;
}
.footercontainer .socialscontainer {
  float: right;
  margin-right: 12vw;
}
.footercontainer .socialscontainer .donatebtn {
  width: 150px;
  height: 40px;
  display: block;
  background-color: #19180a;
  color: #f4f3ee;
  text-decoration: none;
  font-size: 16pt;
  font-family: "Abel";
  text-align: center;
  line-height: 40px;
  border-radius: 5px;
  transition: ease all 0.2;
  opacity: 0.8;
}
.footercontainer .socialscontainer .donatebtn:hover {
  cursor: pointer;
  opacity: 1;
  transform: translateY(-2px);
}
.footercontainer .socialscontainer svg {
  margin-right: 20px;
  font-size: 30pt;
  color: #f4f3ee;
  transition: ease all 0.2s;
  opacity: 0.8;
}
.footercontainer .socialscontainer svg:hover {
  color: white;
  cursor: pointer;
  transform: translateY(-2px);
  opacity: 1;
}
.footercontainer .cityimg {
  width: 100vw;
  position: absolute;
  bottom: 0;
  left: 0;
  filter: brightness(0.8);
}

@media (max-width: 1020px) {
  .navbarcontainer {
    height: 50px;
    padding-right: 0;
  }
  .navbarcontainer .mobilemenubtn {
    display: block;
    position: absolute;
    top: 19px;
    right: 20px;
    width: 30px;
    height: 15px;
  }
  .navbarcontainer .mobilemenubtn .btntop {
    width: 100%;
    height: 2px;
    background-color: white;
    position: absolute;
    top: 0;
    transition: ease all 0.2s;
  }
  .navbarcontainer .mobilemenubtn .btnbot {
    width: 100%;
    height: 2px;
    background-color: white;
    position: absolute;
    bottom: 0;
    transition: ease all 0.2s;
  }
  .navbarcontainer .openmenubtn .btntop {
    transform: rotate(45deg);
    top: 50%;
  }
  .navbarcontainer .openmenubtn .btnbot {
    transform: rotate(-45deg);
    bottom: 39%;
  }
  .navbarcontainer .slogan {
    position: absolute;
    left: 50%;
    margin-left: -8.5%;
    transition: ease all 0.2s;
  }
  .navbarcontainer ul {
    margin-top: 55px;
    margin-right: -55%;
  }
  .navbarcontainer ul a {
    margin-right: 0px;
    background-color: #19180a;
    margin-top: 5px;
    display: block;
    padding: 10px 15px;
    opacity: 0.95;
  }
  .navbarcontainer .activenavmenu {
    margin-right: 5px !important;
  }
  .homecontainer {
    height: 60vh;
  }
  .homecontainer .slogancontainer {
    top: calc(18vh - 30px);
  }
  .homecontainer .slogancontainer .slogan {
    width: 80vw;
    transform: rotate(0deg);
  }
  .homecontainer .laxcontainer {
    /* The image used */
    background-image: url("./assets/images/mwffBannerPic.png");
    background-position: top;
    background-size: 1100px 580px;
  }
  .homecontainer .imgfilter {
    height: 60vh;
  }
  .homecontainer .bottomgradient {
    bottom: -5px;
  }
  .aboutcontainer {
    height: 80vh;
    min-height: 600px;
  }
  .aboutcontainer .leftpic {
    width: 88vw;
    height: 50%;
    position: absolute;
    left: 5vw;
    top: 5vh;
  }
  .aboutcontainer .leftpic .leftimg {
    width: 100%;
    box-shadow: 15px 15px 0px #801b1b;
  }
  .aboutcontainer .rightdetails {
    top: calc(25vh + 70px);
    width: 100vw;
    padding: 30px;
    padding-right: 30px;
    padding-left: 40px;
    font-size: 3.5vw;
    line-height: 5vw;
    height: 50%;
  }
  .howcontainer {
    display: inline-block;
    height: initial;
    gap: 10px;
    padding: 10px;
    align-content: flex-start;
  }
  .howcontainer .how {
    height: 150px;
    display: inline-block;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 5%;
    min-height: 290px;
    background-color: #f4f3ee;
    padding: 30px;
    position: relative;
    border-radius: 3px;
    box-shadow: -6px 6px 0px #5cb83b;
  }
  .howcontainer .how h1 {
    font-size: 17pt;
    font-family: "Cinzel";
    font-weight: 800;
    color: #19180a;
    text-align: center;
    width: 100%;
    margin-top: calc(12vh - 30px);
    text-transform: uppercase !important;
  }
  .howcontainer .how p {
    position: absolute;
    width: 80%;
    left: 11%;
    top: calc(22vh - 30px);
    font-family: "Roboto";
    line-height: 22px;
    font-size: 12pt;
  }
  .howcontainer .how .submitbtn {
    width: 100px;
    right: 10px;
    left: initial;
    margin-left: 0px;
    bottom: 10px;
    background-color: #801b1b;
    color: #f4f3ee;
    padding: 8px 10px;
    font-family: "Abel";
    font-size: 13pt;
    font-weight: 400;
  }
  .howcontainer .how .ticketbtn {
    bottom: 15px;
  }
  .howcontainer .how .directions {
    bottom: 15px;
    left: 5vw;
  }
  .howcontainer .how svg {
    font-size: 40pt;
    position: absolute;
    width: 60px;
    left: 50%;
    top: calc(9vh - 40px);
    margin-left: -30px;
  }
  .schedcontainer {
    padding-top: 50px;
    padding-bottom: 20px;
    height: 79vh;
    min-height: 630px;
    width: 100vw;
    background-color: #19180a;
    position: relative;
  }
  .schedcontainer svg {
    position: absolute;
    top: 70px;
    right: -15px;
    font-size: 50pt;
    color: #f4f3ee;
  }
  .schedcontainer .schedref {
    margin-top: -90px;
  }
  .schedcontainer h2 {
    font-size: 15pt;
    font-family: "Abel";
    width: 100vw;
    position: absolute;
    text-align: center;
    left: 0;
    color: #f4f3ee;
  }
  .schedcontainer .datescontainer {
    width: 100vw;
    position: absolute;
    left: 1vw;
    display: block;
    flex-wrap: wrap;
    height: 100vh;
  }
  .schedcontainer .datescontainer .date {
    width: 45vw;
    height: 30vw;
    position: relative;
    display: inline-block;
  }
  .schedcontainer .datescontainer .date .circle {
    width: 100%;
    height: 100%;
    background-color: #801b1b;
    border-radius: 5px;
    position: absolute;
    left: 0.5vw;
    margin-left: 2.5vw;
    box-shadow: 2px 1px 5px black;
    top: 60px;
    text-align: center;
    overflow: hidden;
  }
  .schedcontainer .datescontainer .date .circle h1 {
    top: 0;
    width: 100%;
    height: 50px;
    line-height: 30px;
    color: #f4f3ee;
    font-family: "mwffHeadFont";
    font-size: 21pt;
    text-shadow: -3px 4px 0px #19180a;
  }
  .schedcontainer .datescontainer .date .circle h1 span {
    font-family: "Abel";
    font-size: 12pt;
    text-shadow: none;
    margin-top: 10px;
    line-height: 20px;
    width: 80%;
    margin-left: 10%;
    display: block;
  }
  .schedcontainer .datescontainer .date .circle h1 .smaller {
    font-size: 10pt;
  }
  .schedcontainer .datescontainer .date2 .circle {
    top: 60px;
    bottom: initial;
    margin-left: 5vw;
  }
  .categoriescontainer {
    height: 45vh;
    min-height: 420px;
    width: 100vw;
    padding-bottom: 20px;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer and Edge */
  }
  .categoriescontainer .categoriesref {
    margin-top: -90px;
  }
  .categoriescontainer .cattitle {
    position: absolute;
    width: 100vw;
    text-align: center;
    color: #f4f3ee;
    top: 0;
    left: 0;
    font-family: "Cinzel";
    display: block;
    font-size: 24pt;
  }
  .categoriescontainer .categorycontainer {
    position: relative;
    height: 100%;
    gap: 10px;
    padding: 40px 20px 85px 40px;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer and Edge */
  }
  .categoriescontainer .categorycontainer .category {
    width: 50vw;
    display: block;
    flex: initial;
    margin-top: 50px;
  }
  .categoriescontainer .categorycontainer .category .imgcontainer {
    width: 100%;
    height: 50%;
  }
  .categoriescontainer .categorycontainer .category .catdets {
    width: 100%;
    position: absolute;
    top: 50%;
  }
  .categoriescontainer .categorycontainer .category .catdets h1 {
    width: 100%;
    margin-left: 10px;
    font-family: "Abel";
    font-size: 17pt;
  }
  .categoriescontainer .categorycontainer .category .catdets h1 svg {
    margin-bottom: -4px;
    margin-right: 10px;
  }
  .categoriescontainer .categorycontainer .category .catdets p {
    margin-left: 20px;
    font-family: "Roboto";
    width: 90%;
  }
  .categoriescontainer .categorycontainer .category .catdets p span {
    border-radius: 4px;
    display: inline-block;
    padding: 3px 7px;
    border: 1px solid black;
    box-shadow: 1px 2px 0px rgba(0, 0, 0, 0.2);
    margin: 0px 4px 10px 0px;
    text-transform: uppercase;
    font-size: 2.5vw;
  }
  .categoriescontainer .hr {
    bottom: 0px;
  }
  .selectionscontainer {
    height: 73vh;
    width: 100vw;
    background-color: #19180a;
    position: relative;
  }
  .selectionscontainer .selectionsref {
    position: absolute;
    top: 0px;
    margin-top: -90px;
    left: 0;
    width: 100vw;
    height: 10px;
    opacity: 0;
  }
  .selectionscontainer .selectionstitle {
    position: absolute;
    width: 100vw;
    text-align: center;
    color: #f4f3ee;
    top: 0;
    left: 0;
    font-family: "Cinzel";
    display: block;
    font-size: 24pt;
  }
  .selectionscontainer .selectioncontainer {
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer and Edge */
    width: 100vw;
    height: 100%;
    gap: 10px;
    padding: 130px 30px 60px 20px;
    position: relative;
    display: flex;
  }
  .selectionscontainer .selectioncontainer .selection {
    width: 70vw;
    background-color: #f4f3ee;
    border-radius: 5px;
    display: block;
    position: relative;
  }
  .selectionscontainer .selectioncontainer .selection .selectioncount {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 50px;
    padding-left: 5px;
    background-color: white;
    line-height: 20px;
  }
  .selectionscontainer .selectioncontainer .selection .imgcontainer {
    width: 70vw;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .selectionscontainer .selectioncontainer .selection .imgcontainer img {
    height: 100%;
    width: 70vw;
  }
  .awardscontainer {
    padding-bottom: 30px;
  }
  .awardscontainer h1 {
    font-size: 24pt;
  }
  .awardscontainer ul {
    width: 100vw;
    margin-left: 0vw;
    padding: 0;
  }
  .awardscontainer ul li {
    color: white;
    width: 33.3333333333%;
    height: 28vw;
  }
  .awardscontainer ul li svg {
    font-size: 22pt;
    margin-top: 8%;
  }
  .awardscontainer ul li h2 {
    text-align: center;
    position: absolute;
    width: 80%;
    top: 30%;
    margin-left: 10%;
    font-family: "Abel";
    font-size: 11pt;
  }
  .awardscontainer ul li:nth-child(odd) {
    background-color: rgba(200, 200, 200, 0.2);
  }
  .footercontainer {
    padding: 10px 5px;
    height: 35vh;
    min-height: 280px;
  }
  .footercontainer .disclaimercontainer {
    font-size: 3vw;
  }
  .footercontainer .disclaimercontainer .cccplug {
    font-family: "Abel";
    color: #f4f3ee;
  }
  .footercontainer .disclaimercontainer .cccplug a {
    color: #19180a;
    text-decoration: none;
  }
  .footercontainer .logocontainer {
    position: absolute;
    bottom: 40px;
    left: 12%;
    height: 120px;
    width: 120px;
  }
  .footercontainer svg {
    opacity: 1;
  }
}/*# sourceMappingURL=index.css.map */